import Slider from 'react-slick';
import css from './Carousel.module.css';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import banner1 from '../../../assets/main-page/banner-1.webp';
import banner2 from '../../../assets/main-page/banner-2.webp';
import banner3 from '../../../assets/main-page/banner-3.webp';
import banner4 from '../../../assets/main-page/banner-4.webp';
import bannerDesktop1 from '../../../assets/main-page/banner-1-desktop.webp';
import bannerDesktop2 from '../../../assets/main-page/banner-2-desktop.webp';
import bannerDesktop3 from '../../../assets/main-page/banner-3-desktop.webp';
import bannerDesktop4 from '../../../assets/main-page/banner-4-desktop.webp';
import { GrayButton } from '../../../components/Button/Button';
import { NamedLink } from '../../../components';

const items = [
  {
    img: banner1,
    imgDesktop: bannerDesktop1,
    title: 'Train with professionals',
    desc: 'Singaporean national athletes are on ProLed!',
    color: '#1C1C1C',
    link: (
      <NamedLink name="CategoriesPage">
        <button className={css.cta}>Explore</button>
      </NamedLink>
    ),
  },
  {
    img: banner2,
    imgDesktop: bannerDesktop2,
    title: 'First booking without fees',
    desc: 'Book within 24 hours of registration and pay only for the service or product.',
    color: '#1C1C1C',
    link: (
      <NamedLink name="SignupPage">
        <GrayButton className={css.button}>Create an account</GrayButton>
      </NamedLink>
    ),
  },
  {
    img: banner3,
    imgDesktop: bannerDesktop3,
    title: 'Online courses',
    desc: 'Train online when it’s convenient for you',
    color: '#fff',
    link: (
      <NamedLink name="CategoriesPage">
        <button className={css.cta}>Explore</button>
      </NamedLink>
    ),
  },
  {
    img: banner4,
    imgDesktop: bannerDesktop4,
    title: 'Personal trainings',
    desc: 'Say goodbye to one-size-fits-all routines. Your fitness, your way!',
    color: '#1C1C1C',
    link: (
      <NamedLink name="CategoriesPage">
        <GrayButton className={css.button}>Explore</GrayButton>
      </NamedLink>
    ),
  },
];

const Carousel = () => {
  const ref = useRef();
  const [active, setActive] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setActive(next),
  };

  return (
    <div className={css.carouselContainer}>
      <Slider {...settings} ref={ref}>
        {items.map((item, index) => (
          <div className={css.carouselItem} key={index}>
            <img src={item.img} className={css.carouselImage} />
            <img src={item.imgDesktop} className={css.carouselImageDesktop} />
            <div className={css.carouselContent}>
              <h2 style={{ color: item.color }} className={css.carouselTitle}>
                {item.title}
              </h2>
              <p style={{ color: item.color }} className={css.carouselText}>
                {item.desc}
              </p>

              {item.link}
            </div>
          </div>
        ))}
      </Slider>
      <div className={css.carouselDots}>
        {items.map((_, index) => (
          <div
            onClick={() => ref.current.slickGoTo(index)}
            className={classNames(css.carouselDot, active == index && css.carouselDotActive)}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
