import css from './AllServices.module.css';
import fitnessImg from '../../../assets/main-page/fitness.webp';
import sportsImg from '../../../assets/main-page/sports.webp';
import trainProImg from '../../../assets/main-page/train-pro.webp';
import { NamedLink } from '../../../components';

const AllServices = () => {
  return (
    <div className={css.allServices}>
      <h2 className={css.allServicesTitle}>All services</h2>
      <div className={css.allServicesItems}>
        <div className={css.allServicesItem}>
          <NamedLink
            name="CategoriesPage"
            to={{
              search:
                'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_category=fitness',
            }}
          >
            <div className={css.allServicesItemBanner}>
              <img src={fitnessImg} alt="fitness" />
              <h3 className={css.allServicesItemTitle}>Fitness</h3>
              {/* <p className={css.allServicesItemDesc}>126 services</p> */}
            </div>
          </NamedLink>
          <div className={css.allServicesItemList}>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=personal%20training&pub_category=fitness',
              }}
              className={css.allServicesItemListItem}
            >
              Personal training
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=strength%20training&pub_category=fitness',
              }}
              className={css.allServicesItemListItem}
            >
              Strength Training
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              className={css.allServicesItemListItem}
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=functional%20training&pub_category=fitness',
              }}
            >
              Functional Training
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=cardio&pub_category=fitness',
              }}
              className={css.allServicesItemListItem}
            >
              Cardio
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=stretching&pub_category=fitness',
              }}
              className={css.allServicesItemListItem}
            >
              Stretching
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=sports recovery&pub_category=fitness',
              }}
              className={css.allServicesItemListItem}
            >
              Sports Recovery
            </NamedLink>
          </div>
        </div>
        <div className={css.allServicesItem}>
          <NamedLink
            name="CategoriesPage"
            to={{
              search:
                'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_category=sports',
            }}
          >
            <div className={css.allServicesItemBanner}>
              <img src={sportsImg} alt="sports" />
              <h3 className={css.allServicesItemTitle}>Sports</h3>
              {/* <p className={css.allServicesItemDesc}>126 services</p> */}
            </div>
          </NamedLink>
          <div className={css.allServicesItemList}>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=combat sports&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Combat sports
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=football&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Football
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=badminton&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Badminton
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=basketball&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Basketball
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=tennis&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Tennis
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=swimming&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Swimming
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=volleyball&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Volleyball
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=table tennis&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Table tennis
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=gymnastics&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Gymnastics
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=track & field&pub_category=sports',
              }}
              className={css.allServicesItemListItem}
            >
              Track & Field
            </NamedLink>
          </div>
        </div>
        <div className={css.allServicesItem}>
          <NamedLink
            name="CategoriesPage"
            to={{
              search: 'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873',
            }}
          >
            <div className={css.allServicesItemBanner}>
              <img src={trainProImg} alt="train-pro" />
              <h3 className={css.allServicesItemTitle}>Train with a pro</h3>
              {/* <p className={css.allServicesItemDesc}>126 services</p> */}
            </div>
          </NamedLink>
          <div className={css.allServicesItemList}>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=olympic athletes',
              }}
              className={css.allServicesItemListItem}
            >
              Olympic Athletes
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=SEA games athlete',
              }}
              className={css.allServicesItemListItem}
            >
              SEA Games Athlete
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=SEA games athlete',
              }}
              className={css.allServicesItemListItem}
            >
              Student Athlete
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=university athlete',
              }}
              className={css.allServicesItemListItem}
            >
              University Athlete
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=SEMI pro',
              }}
              className={css.allServicesItemListItem}
            >
              SEMI Pro
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=under 20 national team',
              }}
              className={css.allServicesItemListItem}
            >
              Under 20 National Team
            </NamedLink>
            <NamedLink
              name="CategoriesPage"
              to={{
                search:
                  'bounds=1.68008452%2C104.08284892%2C1.00507067%2C103.59952873&pub_activity=national athletes',
              }}
              className={css.allServicesItemListItem}
            >
              National Athletes
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllServices;
