import css from './CallToAction.module.css';
import { GrayButton } from '../../../components/Button/Button';

const CallToAction = () => {
  const handleChat = () => {
    window.Intercom('show');
  };

  return (
    <div className={css.cta}>
      <div>
        <h2 className={css.cta_title}>Don't know who fits you better?</h2>
        <p className={css.cta_desc}>Ask us, and we'll help you find a Pro!</p>
      </div>
      <GrayButton onClick={handleChat}>Chat Now</GrayButton>
    </div>
  );
};

export default CallToAction;
