import { compose } from 'redux';
import { Footer, LayoutWrapperFooter, LayoutWrapperTopbar, Page } from '../../components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '../../config';
import Toolbar from '../../components/Toolbar/Toolbar';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { injectIntl } from 'react-intl';
import facebookImage from '../../assets/proleadFacebook.jpeg';
import twitterImage from '../../assets/proleadTwitter.jpeg';
import css from './MainPage.module.css';
import Categories from './Categories/Categories';
import Carousel from './Carousel/Carousel';
import Services from './Services/Services';
import AllServices from './AllServices/AllServices';
import CallToAction from './CallToAction/CallToAction';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import CategoriesBar from '../../components/CategoryBar/CategoriesBar';

const MainPageComponent = props => {
  const { scrollingDisabled, intl, currentUser, history } = props;
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  if (currentUser?.id) {
    history.push(createResourceLocatorString('CategoriesPage', routeConfiguration(), {}));
  }

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutWrapperTopbar>
        <Toolbar />
        <CategoriesBar />
      </LayoutWrapperTopbar>
      <div className={css.homeContainer}>
        <Carousel />
        <Services />
        <AllServices />
        <CallToAction />
      </div>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const MainPage = compose(withRouter, connect(mapStateToProps), injectIntl)(MainPageComponent);

export default MainPage;
